import React from 'react';
import './_Loading.scss';
// import * as animationData from '../../assets/preloader.json';

function Loading() {
  return (
    <div className="Loading">
      <lottie-player
        src="https://assets9.lottiefiles.com/datafiles/Hhw0wgYmETDTkxW/data.json"
        background="#ED4C5C"
        speed="1"
        style={{ width: 300, height: 300 }}
        loop
        autoplay
      ></lottie-player>
    </div>
  );
}

export default Loading;
