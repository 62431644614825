import { createStore, combineReducers } from 'redux';
import rootState from './reducers/courses';

const reducers = combineReducers({
  rootState,
});

const store = createStore(reducers);

export { store };
