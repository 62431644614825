const TYPES = {
  SET_COURSES: 'SET_COURSES',
};

const setCourses = data => {
  return {
    type: TYPES.SET_COURSES,
    payload: data,
  };
};

export { TYPES, setCourses };
