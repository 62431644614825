import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Provider as ReduxProvider } from 'react-redux';
import { store } from './store/index.js';

import AppProvider from './hooks';
import './App.scss';

import Main from './pages/Main/Main.js';

const App: React.FC = () => (
  <BrowserRouter>
    <ReduxProvider store={store}>
      <AppProvider>
        <div className="App">
          <div className="content-wrapper">
            <Main />
          </div>
        </div>
      </AppProvider>
    </ReduxProvider>
  </BrowserRouter>
);

export default App;
