import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PUBLIC_ROUTES } from '../../routes/routes';
import { createBrowserHistory } from 'history';
import Loading from '../../components/Loading/Loading';

const LandPage = React.lazy(() => import('../Land/Land'));
const ProductPage = React.lazy(() => import('../Product/Product'));
const NinjacastPage = React.lazy(() => import('../Ninjacast/Ninjacast'));
const CertificatePage = React.lazy(() => import('../Certificate'));
const NotFoundPage = React.lazy(() => import('../NotFoundPage'));

export const history = createBrowserHistory();

function Main() {
  return (
    <React.Suspense fallback={<Loading />}>
      <Switch history={history}>
        {/* <Route exact path="/courses/:course" component={ProductPage} />
        <Route exact path={PUBLIC_ROUTES.NINJACAST} component={NinjacastPage} /> */}
        <Route
          exact
          path={PUBLIC_ROUTES.LAND_PAGE}
          component={CertificatePage}
        />

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </React.Suspense>
  );
}

export default Main;
